<template>
    <div class="time-tracking-page">
      <h1>Tracking Dashboard</h1>
    <div class="filters">
      <TimeTrackingFilters
        :users="users"
        :statuses="statuses"
        @filter-changed="handleFilterChange"
      />

      <div class="search-bar">
        <label for="taskSearch">Search Task Number:</label>
        <input 
          id="taskSearch" 
          v-model="searchQuery" 
          @input="handleTaskSearch" 
          list="taskNumbers" 
          placeholder="Type task number..." 
        />
        <datalist id="taskNumbers">
          <option v-for="task in taskNumberSuggestions" :key="task" :value="task"></option>
        </datalist>
      </div>
  
    </div>
    <div class="filter_content">
      <div class="filter_table"><TimeTrackingTable :timeEntries="formattedTimeEntries" /></div>
      <div class="filter_pie"><PieChart ref="pieChart" :chartData="pieChartData" /></div>
    </div>  
  
      
    </div>
  </template>
  
  <script>
  import { getAllTrackedTimes } from '../api/apiService';
  import TimeTrackingTable from '@/components/TimeTrackingTable.vue';
  import TimeTrackingFilters from '@/components/TimeTrackingFilters.vue';
  import PieChart from '@/components/PieChart.vue';
  
  export default {
    name: 'DashboardPage',
    components: { TimeTrackingTable, TimeTrackingFilters, PieChart },
    data() {
      return {
        timeEntries: [],
        filteredTimeEntries: [],
        users: [],
        statuses: ['Office', 'Produktion', 'Konfektion', 'Handlauf', 'Allgemein'],
        filters: {
          user: null,
          status: null,
          date: null,
        },
        searchQuery: '',
        taskNumberSuggestions: [],
      };
    },
    computed: {

        pieChartData() {
            // Define the mapping of statuses to categories
            const workstatusCategories = {
            Office: ['Freigabe', 'Zahlung', 'Bestellung Glas'],
            Produktion: ['Produktion'],
            Konfektion: ['Konfektionieren', 'Vorbereiten Farbe'],
            Handlauf: ['Handlauf'],
            };

            // Initialize counters for each category
            const workstatusTimes = {
            Office: 30,
            Produktion: 10,
            Konfektion: 100,
            Handlauf: 120,
            Allgemein: 50,
            };

            // Aggregate time for each category
            this.filteredTimeEntries.forEach(entry => {

            const matches = entry.time.match(/(?:(\d+)h)?\s*(?:(\d+)m)?\s*(?:(\d+)s)?/);

                if (matches) {

                    const hours = parseInt(matches[1] || '0', 10);
                    const minutes = parseInt(matches[2] || '0', 10);
                    const seconds = parseInt(matches[3] || '0', 10);
                    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

                    let categorized = false;
                    for (const [workstatus, statuses] of Object.entries(workstatusCategories)) {
                    if (statuses.includes(entry.status)) {
                        workstatusTimes[workstatus] += totalSeconds;
                        categorized = true;
                        break;
                    }
                    }

                    if (!categorized) {
                    workstatusTimes.Allgemein += totalSeconds;
                    }
                }
            });

            // Transform the aggregated data into a single dataset for Chart.js
            return {
            labels: Object.keys(workstatusTimes), // Category names: Office, Produktion, etc.
            datasets: [
                {
                data: Object.values(workstatusTimes), // Aggregated values for the pie chart
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
                },
            ],
            };
    },
    formattedTimeEntries() {
        return this.filteredTimeEntries.map(entry => {
            const [year, month, day] = entry.date.split('-');
            return {
            ...entry,
            date: `${day}.${month}.${year}`,
            };
        });
        },
    },
    methods: {
        async fetchTimeEntries() {
            console.log('fetch data');
            try {
                const response = await getAllTrackedTimes();
                const details = response.details;             

                this.timeEntries = details.map(entry => ({
                user: entry.firstname,
                taskNumber: entry.task_number,
                status: entry.task_status,
                time: entry.time,
                date: entry.date,
                }));

                this.filteredTimeEntries = this.timeEntries;

                // Extract unique users for the filter
                this.users = [...new Set(this.timeEntries.map(entry => entry.user))];
                this.taskNumberSuggestions = [...new Set(this.timeEntries.map(entry => entry.taskNumber))];
            } catch (error) {
                console.error('Error fetching time entries:', error);
            }
        },

    handleFilterChange(newFilters) {
        this.filters = newFilters;
        this.applyFilters();
        // Call the manualUpdate method with new chart data
        this.updatePieChart();

    },
    updatePieChart() {
        if (this.$refs.pieChart) {
        this.$refs.pieChart.updateChart(this.pieChartData);
        } else {
        console.error("PieChart ref not found.");
        }
    },
    handleTaskSearch() {
      if (this.searchQuery) {
        this.filteredTimeEntries = this.timeEntries.filter(entry =>
          entry.taskNumber.includes(this.searchQuery)
        );
      } else {
        this.applyFilters();
        // Call the manualUpdate method with new chart data
        this.updatePieChart();

      }
    },
    applyFilters() {
      this.filteredTimeEntries = this.timeEntries.filter(entry => {
        const matchesUser = !this.filters.user || entry.user === this.filters.user;
        const matchesStatus = !this.filters.status || entry.status === this.filters.status;
        const matchesDate = !this.filters.date || new Date(entry.date).toISOString().split('T')[0] === this.filters.date;
        return matchesUser && matchesStatus && matchesDate;
      });
    },
    },
    mounted() {
      this.fetchTimeEntries();
    },
  };
  </script>
  
  <style scoped>

  h1{

    font-size: 18px;
    margin: 25px 0;
  }

  .time-tracking-page {
    padding: 20px;
  }

  .filter_content{

    display: flex;
  }

  .search-bar {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-bar input {
    padding: 5px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  </style>