// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/pages/LoginPage.vue';
import TaskPage from '@/pages/TaskPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import DashboardPage from '@/pages/DashboardPage.vue';


const routes = [
  { path: '/', name: 'Login', component: LoginPage },
  { path: '/auftraege', name: 'TaskPage', component: TaskPage, meta: { requiresAuth: true } },
  { path: '/profile', name: 'ProfilePage', component: ProfilePage, meta: { requiresAuth: true } },
  { path: '/dashboard', name: 'DashboardPage', component: DashboardPage, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authToken');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
