<template>
    <div class="pie-chart" ref="chartContainer">
      <!-- Canvas will be dynamically created and destroyed -->
    </div>
  </template>
  
  <script>
  import { Chart, PieController, ArcElement, Tooltip, Legend } from 'chart.js';
  
  // Register required Chart.js components
  Chart.register(PieController, ArcElement, Tooltip, Legend);
  
  export default {
    name: 'PieChart',
    props: {
      chartData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        chartInstance: null,
        chartReady: false
      };
    },
    watch: {

    },
    methods: {
    createCanvas() {
        console.log('add canvas');
        const container = this.$refs.chartContainer;
            if (container) {
                const canvas = document.createElement('canvas');
                container.appendChild(canvas);
                return canvas;
            } else {
                console.error("Chart container not found.");
                return null;
            }
    },
    destroyCanvas() {
      const container = this.$refs.chartContainer;
      if (container) {
        container.innerHTML = ''; // Clear all child elements, including the canvas
      }
    },

    renderChart(data) {

        this.destroyCanvas(); // Ensure any existing canvas is removed
        const canvas = this.createCanvas(); // Create a new canvas

        console.log('render chart');
        if (canvas) {
            const ctx = canvas.getContext('2d');
            console.log(data);

            const config = {
                type: 'pie',
                data: data,
                options: {
                responsive: true,
                animation: {
                    animateScale: true,
                },
                layout: {
                        padding: 20
                    },
                        plugins: {
                    legend: {
                    position: 'top',
                    labels: {
                        padding: 10, // Ensure padding is explicitly defined
                        font: {
                        size: 14, // Set font size to prevent undefined font options
                        },
                    },
                    },
                    tooltip: {
                    enabled: true, // Ensure tooltips are enabled
                    },
                },
                },
            };
        
          this.chartInstance = new Chart(ctx, config);

        } else {
          console.error("Canvas element not found or not yet rendered.");
        }
      },
      updateChart(data) {

        console.log('update');

        this.renderChart(data);
        }
    },
    mounted() {
        this.renderChart(this.chartData);
    },
    unmounted() {
        if (this.chartInstance) {
      this.chartInstance.destroy(); // Clean up chart instance
    }
    },

  };
  </script>
  
  <style scoped>
  .pie-chart {
    max-width: 400px;
    margin: 20px auto;
  }
  </style>
  