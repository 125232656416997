<template>
    <table class="time-tracking-table">
      <thead>
        <tr>
          <th>User</th>
          <th>Task Number</th>
          <th>Status</th>
          <th>Time</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in timeEntries" :key="entry.id">
          <td>{{ entry.user }}</td>
          <td>{{ entry.taskNumber }}</td>
          <td>{{ entry.status }}</td>
          <td>{{ entry.time }}</td>
          <td>{{ entry.date }}</td>
        </tr>
        <tr>
            <td colspan="3"><strong>Total</strong></td>
            <td>{{ formatTotalTime(totalTimes) }}</td>
        </tr>
      </tbody>
    </table>
  </template>
  
  <script>
  export default {
    name: 'TimeTrackingTable',
    props: {
        timeEntries: {
        type: Array,
        required: true,
      },
    },
    computed: {
      totalTimes() {
        // Calculate total time of all entries
        return this.timeEntries.reduce((total, entry) => {
          const timeParts = entry.time.match(/(?:(\d+)h)?\s*(?:(\d+)m)?\s*(?:(\d+)s)?/);
          if (timeParts) {
            const hours = parseInt(timeParts[1] || '0', 10);
            const minutes = parseInt(timeParts[2] || '0', 10);
            const seconds = parseInt(timeParts[3] || '0', 10);
            return total + hours * 3600 + minutes * 60 + seconds;
          }
          return total;
        }, 0);
      },
    },
    methods: {
      formatTotalTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h ${minutes}m ${secs}s`;
      },
    },
  };
  </script>
  
  <style scoped>
    .time-tracking-table { 
        width: 100%;
        border-collapse: collapse;
    }
    
    .time-tracking-table tbody{

        font-size: 12px;
    }
    
    .time-tracking-table th, 
    .time-tracking-table td {
        border: 1px solid #ccc;
        padding: 10px;
    }
    
    </style>
  