<template>
    <div class="filters">
      <select v-model="filters.user" @change="emitFilterChange">
        <option value="">All Users</option>
        <option v-for="user in users" :key="user" :value="user">{{ user }}</option>
      </select>
  
      <select v-model="filters.status" @change="emitFilterChange">
        <option value="">All Statuses</option>
        <option v-for="status in statuses" :key="status" :value="status">{{ status }}</option>
      </select>
  
      <input type="date" v-model="filters.date" @change="emitFilterChange" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'TimeTrackingFilters',
    props: ['users', 'statuses'],
    data() {
      return {
        filters: {
          user: null,
          status: null,
          date: null,
        },
      };
    },
    methods: {
      emitFilterChange() {
        this.$emit('filter-changed', { ...this.filters });
      },
    },
  };
  </script>
  
  <style scoped>
  .filters { 
    margin: 20px 0; 

}

</style>
  